import {defineStore} from 'pinia'
import {ref, reactive, onMounted} from 'vue';
import * as cache from '@/utils/cache';
import {chatUserAuth} from '@/api/chat/chatUser';

interface IUserInfo {
    email: string;
}

export const useUserStore = defineStore("userStore", () => {
    const _token_key = "_t_tk"
    const _user_info_key = "_u_i_k"

    const token = ref(cache.GetLocalStorageByString(_token_key) || "");
    const showAuth = ref(false);
    const userInfo = reactive<IUserInfo>(cache.GetLocalStorageByObject(_user_info_key) || {})

    const SetToken = (v: string) => {
        token.value = v;
        cache.SetLocalStorageByString(_token_key, v);
    };

    const SetUserInfo = (obj: IUserInfo) => {
        Object.assign(userInfo, obj);
        cache.SetLocalStorageByObject(_user_info_key, userInfo);
    }

    const GetUserInfo =()=>{
        return cache.GetLocalStorageByObject<IUserInfo>(_user_info_key)
    }

    const SetShowAuth = (v: boolean) => {
        showAuth.value = v;
    }

    const GetToken = () => {
        return token.value
    };

    const Login = async (loginForm: { email: string; password: string; }) => new Promise(async (resolve, reject) => {
        try {
            const {data} = await chatUserAuth(loginForm)
            SetToken(data.data.token);
            SetUserInfo({
                email: loginForm.email,
            })
            return resolve("success");
        } catch (err) {
            return reject(err)
        }
    })

    const LoginOut = () => {
        token.value = "";
        Object.assign(userInfo, {})
        cache.RemoveLocalStorage(_token_key);
        cache.RemoveLocalStorage(_user_info_key);
        showAuth.value = true;
    }

    onMounted(() => {

    })

    return {
        token,
        showAuth,
        Login,
        SetToken,
        GetUserInfo,
        SetUserInfo,
        GetToken,
        SetShowAuth,
        LoginOut,
    }
});
