<template>
    <footer class="footer">
        Copyright © 2023 Tools-AI. All rights reserved.
    </footer>
</template>
<script lang="ts" setup>

</script>
<style lang="scss" scoped>
.footer {
  height: 20px;
  overflow: hidden;
  color: #999;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  justify-content: center;
  box-sizing: border-box;
}
</style>