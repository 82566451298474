import {createRouter, RouteRecordRaw, createWebHashHistory} from 'vue-router';
import Layout from '@/layout/Layout.vue'

const routes: RouteRecordRaw[] = [
    // {
    //     path: "/",
    //     component: ()=>import("@/views/login/login.vue"),
    // },
    {
        path: "/",
        component: Layout,
        redirect: "/start/index",
        children: [],
    },
    {
        path: "/start",
        component: Layout,
        redirect: "/start/index",
        children: [
            {
                path: "index",
                component: () => import("@/views/start/index.vue"),
            }
        ]
    },
    {
        path: "/article",
        component: Layout,
        redirect: "/article/index",
        children: [
            {
                path: "index",
                component: () => import("@/views/home/index.vue"),
            }
        ]
    }];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;