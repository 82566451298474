<template>
    <aside class="app-sidebar">
        <section class="p-sidebar-block">
            <div class="u-block-name">工作区</div>
            <div class="u-block">
                <div class="u-block-item u-block-item__active">
                    <svg-icon name="application" size="14"/>
                    <div class="name">场景文案创作</div>
                </div>
                <div class="u-block-item">
                    <svg-icon name="application" size="14"/>
                    <div class="name">更多建设中...</div>
                </div>
            </div>
        </section>
    </aside>
</template>
<script lang="ts" setup>
</script>
<style lang="scss" scoped>
.app-sidebar {
  width: 250px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 15px;
}

.p-sidebar-block {
  margin-top: 30px;

  .u-block-name {
    font-size: 12px;
    color: #A2A3A7;
  }

  .u-block {
    padding: 15px 0;

    .u-block-item {
      background-color: transparent;
      padding: 5px 15px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 10px;
      color: #69686E;

      &:hover {
        cursor: pointer;
      }

      .name {
        font-size: 14px;
      }
    }

    .u-block-item__active {
      background-color: #fff;
      color: #69686E;
      font-weight: bold;
      box-shadow: 0 2px 8px 2px #E6E7E9;
    }
  }
}
</style>