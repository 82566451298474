import {App} from "vue";

import SvgIcon from './SvgIcon';
import YSwitch from './YSwitch';
import YDialog from './YDialog';
import YDropdown from './YDropdown';
import YButton from './YButton';
import YInput from './YInput';
import YSelect from './YSelect';
import YImagePreview from "./YImagePreview";

export const RegisterGlobalComponents = (app: App) => {
    app.component("svg-icon",SvgIcon);
    app.component("y-switch",YSwitch);
    app.component("y-dialog",YDialog);
    app.component("y-dropdown",YDropdown);
    app.component("y-button",YButton);
    app.component("y-input",YInput);
    app.component("y-select",YSelect);
    app.component("y-image-preview",YImagePreview);
}