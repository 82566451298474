<template>
    <n-modal v-model:show="showLogin" preset="dialog" class="login-dialog" :closable="false" :show-icon="false"
             transform-origin="center" :mask-closable="false">
        <template #header>
            <div class="dialog-title-warpper">
                <img src="../../assets/images/logo.png"/>
            </div>
        </template>
        <div class="dialog-body-warpper">
            <n-spin :show="loginLoading">
                <div class="u-tip">请使用Chat-AI邮箱登录</div>
                <div class="login-form">
                    <n-form :model="loginForm">
                        <n-form-item :show-label="false">
                            <n-input v-model:value="loginForm.email" placeholder="请输入您的邮箱地址" clearable/>
                        </n-form-item>
                        <n-form-item :show-label="false">
                            <n-input @keydown.enter="loginSubmit()" v-model:value="loginForm.password" placeholder="请输入您的密码" clearable
                                     type="password"/>
                        </n-form-item>
                    </n-form>
                </div>
                <div class="login-action">
                    <n-button @click="loginSubmit()" type="info" style="width:100%;">提交登录</n-button>
                    <a class="link" href="https://chat.yuetchn.top">前往注册></a>
                </div>
                <template #description>
                    登录中...
                </template>
            </n-spin>
        </div>
    </n-modal>
</template>
<script lang="ts" setup>
import {onMounted, watch, ref, reactive} from 'vue'
import {useUserStore} from '@/store/user'
import message from '@/components/YMessage'

const userStore = useUserStore();
const showLogin = ref(false);
const loginForm = reactive({
    email: "",
    password: ""
});
const loginLoading = ref(false);

onMounted(() => {

});

const loginSubmit = async () => {
    try {
        await userStore.Login(loginForm);
        loginLoading.value = true;
        message.info("登录成功");
        setTimeout(() => {
            window.location.reload();
            userStore.SetShowAuth(false);
        }, 1000)
    } finally {
        loginLoading.value = false;
    }
}

watch(() => userStore.showAuth, (v) => {
    if (v) {
        showLogin.value = true;
    }
}, {immediate: true})
</script>
<style lang="scss" scoped>

</style>