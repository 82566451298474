import {createApp} from 'vue'
import {createPinia} from 'pinia'
import Router from "@/router"
import App from './App.vue'
import {RegisterGlobalComponents} from '@/components';
import {RegisterGlobalDirective} from '@/directive';
import 'virtual:svg-icons-register'
import "@/assets/styles/__var.css";
import naive from 'naive-ui'
// 通用字体
import 'vfonts/Lato.css'
// 等宽字体
import 'vfonts/FiraCode.css'

const app = createApp(App)
app.use(createPinia())
app.use(Router)
app.use(naive)

RegisterGlobalDirective(app)
RegisterGlobalComponents(app)
app.mount('#app')
