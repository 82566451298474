<template>
    <div class="app">
        <n-watermark
            content="Tools-AI"
            cross
            fullscreen
            :font-size="16"
            :line-height="16"
            :width="384"
            :height="384"
            :x-offset="12"
            :y-offset="60"
            :rotate="-15"
        />
        <Login/>
        <n-config-provider style="height:100%;" :theme-overrides="{ common: { fontWeightStrong: '600' } }">
            <router-view/>
        </n-config-provider>
    </div>
</template>
<script lang="ts" setup>
import {onMounted, watch} from "vue";
import {useAppStore} from '@/store/app';
import {useUserStore} from '@/store/user';
import Login from '@/layout/components/Login.vue';

const appStore = useAppStore();
const userStore = useUserStore();

onMounted(() => {
    // 设置网站标题
    document.title = import.meta.env.VITE_APP_TITLE;

    // 检测是否启用了暗黑主题
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

    // 初始化时检测一次主题模式
    // if (darkModeQuery.matches) {
    //     appStore.SetTheme("dark")
    // }else {
    //     appStore.SetTheme("light")
    // }

    appStore.SetTheme("light")

    // 监听主题模式的变化
    darkModeQuery.addEventListener('change', (event) => {
        if (event.matches) {
            appStore.SetTheme("dark")
        } else {
            appStore.SetTheme("light")
        }
    });

    watch(() => appStore.theme, (v) => {
        initTheme(v);
    }, {immediate: true, flush: "post"});

    // 移动端active类适配
    document.body.addEventListener('touchstart', function () {
    });
});

const initTheme = (type: "light" | "dark") => {
    const body = document.querySelector("body");
    const lighlightLink = document.querySelector("#lighlightLink") as any;
    if (type === "light") {
        body?.classList.remove("dark");
        body?.classList.add("light");
        if (lighlightLink) {
            lighlightLink.href = "atom-one-light.css"
        }

    } else {
        body?.classList.remove("light");
        body?.classList.add("dark");
        if (lighlightLink) {
            lighlightLink.href = "atom-one-dark.css"
        }
    }
}
const parseQueryString = (url: string) => {
    let queryParams: Record<string, any | any[]> = {};
    const queryString = url.split('?')[1];

    if (queryString) {
        const pairs = queryString.split('&');
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split('=');
            const key = decodeURIComponent(pair[0]);
            const value = decodeURIComponent(pair[1] || '');

            if (queryParams[key]) {
                if (Array.isArray(queryParams[key])) {
                    queryParams[key].push(value);
                } else {
                    queryParams[key] = [queryParams[key], value];
                }
            } else {
                queryParams[key] = value;
            }
        }
    }

    return queryParams;
}


// 登录跳转
const form = parseQueryString(window.location.href);
if (form.t && form.e) {
    userStore.SetToken(form.t || "");
    userStore.SetUserInfo({
        email: form.e,
    });
}

</script>
<style lang="scss" scoped>
.app {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.link-warpper {
  display: flex;
  gap: 8px;
  justify-content: right;

  u {
    font-size: 0.9rem;
    color: #409EFF;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>