import axios, {AxiosError, AxiosResponse} from 'axios';
import {useUserStore} from '@/store/user';
import message from '@/components/YMessage';

const http = axios.create({
    baseURL: import.meta.env.VITE_HTTP_BASE_HOST,
    timeout: 1000 * 100,
});

http.interceptors.request.use(
    (config) => {
        const userStore = useUserStore();

        // loading
        // (config.loading !== false && !config.__timestamp) ? ShowLoading() : null;

        if (userStore.token) {
            config.headers.Authorization = userStore.token;
        }

        return config;
    },
    (err) => {
        // HiddenLoading(true)
        return Promise.reject(err);
    },
);

http.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err: AxiosError<AxiosResponse<any>>) => {
        const userStore = useUserStore();
        console.log(err.response?.status,err)
        if (err.message.includes("timeout")) {
            // errMsg.setErrMsg("连接超时", "error")
            // errMsg.fetry();
        } else {
            switch (err.response?.status) {
                case 401:
                    message.info("登录超时，请重新登录")
                    if (userStore.token) {
                        await userStore.LoginOut();
                    } else {
                        await userStore.SetShowAuth(true);
                    }

                    break;
                case 502:
                    // errMsg.setErrMsg("网关错误", "error");
                    // errMsg.fetry();
                    message.info("网关错误")
                    break;

                default:
                    message.info((err.response?.data as any)?.msg || "连接失败")
                    // errMsg.setErrMsg(err.response?.data.msg || "连接失败", "error");
                    break;
            }
        }

        // 关闭弹窗
        // HiddenLoading(true);

        return Promise.reject(err);
    },
);

export default http;