<template>
    <nav class="app-nav-warpper">
        <div class="u-logo">
            <img src="../assets/images/logo.png"/>
            <!--            <span class="logo">Tools-AI</span>-->
        </div>
        <div class="u-tab">
            <div class="u-tab-item u-tab-item__active" @click="$router.push('/')">
                <svg-icon name="application"/>
                <span class="tab-name">探索</span>
            </div>
            <div class="u-tab-item">
                <svg-icon name="data"/>
                <span class="tab-name">数据源</span>
            </div>
            <div class="u-tab-item">
                <svg-icon name="digital" size="18"/>
                <span class="tab-name">AIT-数藏阁</span>
            </div>
        </div>
        <div class="u-user" v-if="userStore.token" @mouseenter="getUserInfo()">
            <div class="user-info-warpper">
                <img src="../assets/images/ChatGPT-Icon-User-PNG.png"/>
                <span class="user">{{ userStore.GetUserInfo().email[0].toUpperCase() || "U" }}</span>
            </div>
            <div class="user-info-detail-warpper">
                <div class="user-info">
                    <img src="../assets/images/ChatGPT-Icon-User-PNG.png"/>
                    <span>{{
                        `${userStore.GetUserInfo().email.substring(0, 5)}****${userStore.GetUserInfo().email.substring(userStore.GetUserInfo().email.length - 3)}`
                        }}</span>
                </div>
                <div class="user-point">
                    <div class="point">积分&nbsp; <span>{{ userInfo.points }}</span></div>
                    <div class="link" @click="goRecharge()">充值&nbsp;></div>
                </div>
                <!--                <div class="actions">-->
                <!--                    actions-->
                <!--                </div>-->
                <div class="logout">
                    <n-button style="width:100%;" @click="userStore.LoginOut()">退出登录</n-button>
                </div>
            </div>
        </div>
        <div v-else @click="userStore.SetShowAuth(true)">
            立即登录
        </div>
    </nav>
</template>
<script lang="ts" setup>
import {useUserStore} from '@/store/user';
import {chatUserByCurrent_GET} from '@/api/chat/chatUser'
import {onMounted, reactive} from "vue";

const userStore = useUserStore();

const userInfo = reactive<{
    points: number;
}>({
    points: 0,
})

onMounted(() => {

})

const getUserInfo = async () => {
    const {data} = await chatUserByCurrent_GET();
    Object.assign(userInfo, data.data);
}
const goRecharge = () => {
    window.open("https://chat.yuetchn.top/#/pay");
}
</script>
<style lang="scss" scoped>
.app-nav-warpper {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;

  .u-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      width: 140px;
      //height: 70px;
      //height: 30px;
      //border-radius: 50%;
    }

    .logo {
      font-weight: bold;
      font-size: 22px;
    }
  }

  .u-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .u-tab-item {
      background-color: transparent;
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: center;
      padding: 6px 12px;
      border-radius: 10px;
      font-size: 14px;
      color: #797981;

      &:hover {
        cursor: pointer;
        background-color: #fff;
        box-shadow: 0 0 10px 2px #E6E7E9;
      }

      .tab-name {
        font-size: 12px;
      }
    }

    .u-tab-item__active {
      color: #49547B;
      background-color: #fff;
      box-shadow: 0 0 10px 2px #E6E7E9;
    }
  }

  .u-user {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    .user-info-warpper {
      display: flex;
      gap: 10px;
      padding-left: 50px;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .user {
        font-size: 18px;
        font-weight: bold;
      }

    }

    .user-info-detail-warpper {
      display: none;
      position: absolute;
      right: 0;
      background: #fff;
      box-shadow: 0 0 10px 1px #DAF0E4;
      width: 260px;
      top: 60px;
      border-radius: 15px;
      padding: 20px;
      box-sizing: border-box;
      z-index: 99999;

      .user-info {
        display: flex;
        gap: 10px;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
      }

      .user-point {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        background: linear-gradient(to right, #FFF2F5, #FFDFE7);
        border-radius: 12px;
        padding: 10px 15px;
        box-sizing: border-box;
        margin: 20px 0;

        .point {
          font-weight: bold;

          span {
            color: #F9587F;
            font-weight: bold;
            font-size: 16px;
          }
        }

        .link {
          color: #F9587F;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .logout {
        margin-top: 20px;
        box-sizing: border-box;
      }
    }
  }

  .u-user:hover {
    cursor: pointer;

    .user-info-detail-warpper {
      display: block;
    }
  }
}
</style>