import {defineStore} from 'pinia'
import {ref, onMounted} from 'vue';
import * as cache from '@/utils/cache';

export const useAppStore = defineStore("appStore", () => {
    const theme_cache_key = "_t_theme_key"
    const theme = ref<"light" | "dark">(cache.GetLocalStorageByString(theme_cache_key) || "dark");

    const SetTheme = (v: "light" | "dark") => {
        theme.value = v;
        cache.SetLocalStorageByString(theme_cache_key, v);
    };

    onMounted(() => {

    })

    return {
        theme,
        SetTheme
    }
});
